import {useStaticQuery, graphql} from 'gatsby';

const useOliverDemoPage = () => {
  const {contentfulOliverDemoPage} = useStaticQuery(
    graphql`
      query {
        contentfulOliverDemoPage {
          title
          videoUrl
        }
      }
    `,
  );
  return contentfulOliverDemoPage;
};

export default useOliverDemoPage;
