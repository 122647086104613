import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import './RecipeCard.scss';

const RecipeCard = React.forwardRef(({title, image}, ref) => {
  const recipeImage = getImage(image.localFile);
  return (
    <div className="recipe-card" ref={ref}>
      <GatsbyImage
        loading="eager"
        image={recipeImage}
        className="recipe-card__image w-full"
      />
      <p className="desktop:text-xl text-lg recipe-card__title font-semibold">
        {title}
      </p>
    </div>
  );
});

RecipeCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default RecipeCard;
