import {useStaticQuery, graphql} from 'gatsby';

const useContactPage = () => {
  const {contentfulCookiePrompt} = useStaticQuery(
    graphql`
      query {
        contentfulCookiePrompt {
          title
          body {
            raw
          }
        }
      }
    `,
  );
  return contentfulCookiePrompt;
};

export default useContactPage;
