import React from 'react';
import PropTypes from 'prop-types';
import {Checkmark} from '../../../assets/icons';
import './CheckBox.scss';

const CheckBox = ({
  id,
  name,
  label,
  labelClasses,
  inputClasses,
  checked,
  handleChange,
}) => (
  <label
    htmlFor={id}
    className={`check-label my-auto flex items-center font-semibold ${labelClasses}`}>
    <div className="check-container relative">
      <input
        id={id}
        name={name}
        type="checkbox"
        className={inputClasses}
        checked={checked}
        onChange={handleChange}
      />
      {checked && <Checkmark className="absolute top-1 left-1" />}
    </div>
    {label}
  </label>
);

CheckBox.defaultProps = {
  labelClasses: '',
  inputClasses: '',
  handleChange: () => {},
};

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  labelClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
};

export default CheckBox;
