import {useStaticQuery, graphql} from 'gatsby';

const useHouseholdPage = () => {
  const {
    contentfulHouseholdPage,
    allContentfulFeatureAndBenefitsCard,
    allContentfulHouseholdFeaturesMediaTiles,
  } = useStaticQuery(
    graphql`
      query {
        allContentfulFeatureAndBenefitsCard {
          nodes {
            icon {
              file {
                url
                fileName
                contentType
              }
            }
            description {
              raw
            }
            title
            id
            order
          }
        }
        contentfulHouseholdPage {
          id
          mainBanner {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          mainBannerTitle {
            raw
          }
          mainBannerSubtitle {
            raw
          }
          section1Title
          section2BodyText {
            raw
          }
          section2Title {
            raw
          }
          videoTitle
          videoUrl
          videoThumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            description
          }
          section3Description {
            raw
          }
          section3Title {
            raw
          }
          title
        }
        allContentfulHouseholdFeaturesMediaTiles {
          nodes {
            title
            order
            description {
              raw
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2400, placeholder: BLURRED)
                }
              }
              description
            }
            id
          }
        }
      }
    `,
  );
  return {
    contentfulHouseholdPage,
    allContentfulFeatureAndBenefitsCard,
    allContentfulHouseholdFeaturesMediaTiles,
  };
};

export default useHouseholdPage;
