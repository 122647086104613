import {useStaticQuery, graphql} from 'gatsby';

const useSocialMediaLinks = () => {
  const {contentfulSocialMediaLinks} = useStaticQuery(
    graphql`
      query {
        contentfulSocialMediaLinks {
          elseFacebook
          elseInstagram
          elseLinkedin
          elseTwitter
          oliverInstagram
        }
      }
    `,
  );
  return contentfulSocialMediaLinks;
};

export default useSocialMediaLinks;
