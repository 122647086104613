/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {BLOCKS, INLINES, MARKS} from '@contentful/rich-text-types';

// Add support for coloured text in rich text using (text)[#hexcode]
const addColour = children => {
  const mappedChildren = children.flatMap(child => {
    if (typeof child === 'string') {
      // look for matches for (text)[#hexcode]
      const matches = [...child.matchAll(/\((.*?)\)\[(.*?)\]/g)];
      if (matches.length > 0) {
        let result = [];
        // loop through all matches and add to result array
        matches.forEach(match => {
          // spliting the matched string from the text
          const splitArray =
            result.length > 0
              ? result[result.length - 1].split(match[0])
              : child.split(match[0]);
          // replacing the matched string with a <span> tag
          const combinedArray = [
            splitArray[0],
            <span style={{color: match[2]}}>{match[1]}</span>,
            splitArray[1],
          ];
          result.pop();
          result = [...result, ...combinedArray];
        });
        return result;
      }
    }
    return child;
  });
  return mappedChildren;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{addColour(children)}</p>,
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        className="underline"
        href={node.data.uri}
        target={node.data.uri.startsWith('#') ? '_self' : '_blank'}
        rel="noreferrer">
        {addColour(children)}
      </a>
    ),
    [BLOCKS.HEADING_1]: (node, children) => <h1>{addColour(children)}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2>{addColour(children)}</h2>,
  },
};

export const displayRichText = raw => {
  const text = JSON.parse(raw);
  return documentToReactComponents(text, options);
};

export const sleep = milliseconds =>
  new Promise(resolve => setTimeout(resolve, milliseconds));

export default {
  displayRichText,
  sleep,
};
