import React from 'react';
import PropTypes from 'prop-types';

const LoadingIndicator = ({classes}) => (
  <svg className={`animate-spin h-5 w-5 ${classes}`} viewBox="0 0 24 24">
    <circle
      className="opacity-10"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className="opacity-100"
      fill="#FEFEFE"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

LoadingIndicator.propTypes = {
  classes: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  classes: '',
};

export default LoadingIndicator;
