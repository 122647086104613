/* eslint-disable class-methods-use-this */
// import Config from '@elselabs/babel-env';

class Http {
  constructor() {
    this.retries = 0;
    this.timeout = 6000;
  }

  _setHeaders({auth, contentType}) {
    // const version = Config.APP_VERSION;
    return {
      ...(auth ? {Authorization: `Bearer ${auth}`} : undefined),
      ...(contentType ? {'content-type': contentType} : undefined),
      // ...(version ? {'X-ApplicationVersion': version} : undefined),
    };
  }

  _setBody({contentType, obj}) {
    // TODO: support more content-types
    switch (contentType) {
      case 'application/x-www-form-urlencoded':
        return Object.keys(obj)
          .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`,
          )
          .join('&');
      case 'multipart/form-data':
        return obj;
      default:
        return JSON.stringify(obj) || undefined;
    }
  }

  async _parseJSON(response) {
    try {
      const text = await response.text();
      return text ? JSON.parse(text) : {};
    } catch (e) {
      throw e;
    }
  }

  _fetch({
    url,
    auth,
    contentType,
    method,
    obj,
    timeout = this.timeout,
    retries = this.retries,
    ...options
  }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const wrappedFetch = async n => {
        try {
          // setting timeout that throws an error if the request takes
          // longer than the specified timeout
          const timer = setTimeout(() => {
            // if retries was specified, retry the request
            if (n > 0) {
              return retry(n); // eslint-disable-line no-use-before-define
            }
            // if request times out reject the promise
            return reject(new Error('Request timed out'));
          }, timeout || this.timeout);

          const response = await fetch(url, {
            ...options,
            headers: this._setHeaders({auth, contentType}),
            body: obj ? this._setBody({contentType, obj}) : undefined,
            method,
          });

          // clearing timeout
          clearTimeout(timer);

          const {ok, status} = response;

          const data = await this._parseJSON(response);

          const result = {
            status,
            body: data || undefined,
          };

          // if request fails reject the promise
          if (!ok) {
            // if retries was specified, retry the request
            if (n > 0) {
              return retry(n); // eslint-disable-line no-use-before-define
            }
            return reject(result);
          }

          return resolve(result);
        } catch (e) {
          if (n > 0) {
            return retry(n); // eslint-disable-line no-use-before-define
          }
          return reject(e);
        }
      };

      const retry = n => {
        const attempts = n - 1;
        wrappedFetch(attempts);
      };

      await wrappedFetch(retries);
    });
  }

  get(options = {}) {
    return this._fetch({
      method: 'GET',
      ...options,
    });
  }

  post(options = {}) {
    return this._fetch({
      method: 'POST',
      ...options,
    });
  }

  del(options = {}) {
    return this._fetch({
      method: 'DELETE',
      ...options,
    });
  }

  put(options = {}) {
    return this._fetch({
      method: 'PUT',
      ...options,
    });
  }

  head(options = {}) {
    return this._fetch({
      method: 'HEAD',
      ...options,
    });
  }
}

export default new Http();
