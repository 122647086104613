import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './TextArea.scss';

const TextArea = ({
  id,
  maxCharacters,
  placeholder,
  name,
  label,
  error,
  labelClasses,
  textAreaClasses,
  value,
}) => {
  const [characterCount, setCharacterCount] = useState(value.length);
  const handleChange = e => {
    setCharacterCount(Math.min(e.target.value.length, maxCharacters));
  };
  return (
    <label
      className={`text-area-container flex flex-col text-lg font-semibold ${labelClasses} `}
      htmlFor={id}>
      <span className={`text-area-label ${error && 'text-area-label--error'}`}>
        {label}
      </span>
      <textarea
        id={id}
        name={name}
        className={`text-area rounded resize-none text-base focus:outline-none ${
          error ? 'text-area--error' : ''
        } ${textAreaClasses}`}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        rows={9}
      />
      <div className="text-area__underline flex w-full items-center text-xs">
        {error && <span className="text-area__underline__error">{error}</span>}
        <span className="text-area__underline__char-count font-normal ml-auto">
          {characterCount}/{maxCharacters}
        </span>
      </div>
    </label>
  );
};

TextArea.defaultProps = {
  labelClasses: '',
  textAreaClasses: '',
  error: '',
  value: '',
  placeholder: '',
  maxCharacters: 0,
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  maxCharacters: PropTypes.number,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  labelClasses: PropTypes.string,
  textAreaClasses: PropTypes.string,
  value: PropTypes.string,
};

export default TextArea;
