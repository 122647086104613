import React from 'react';
import {useTranslation} from 'react-i18next';
import RouterPaths from '../../../values/RouterPaths';
import {
  FooterLogo,
  Facebook,
  Twitter,
  Linkedin,
  Instagram,
} from '../../../assets/icons';
import {CustomLink} from '../../common';
import {useSocialMediaLinks} from '../../../hooks/queries';
import './Footer.scss';

function Footer() {
  const {t} = useTranslation();
  const content = useSocialMediaLinks();
  const date = new Date();

  const routes = [
    {href: RouterPaths.COMMERCIAL, name: 'commercial'},
    {href: RouterPaths.HOUSEHOLD, name: 'household'},
    {href: RouterPaths.ABOUT, name: 'about'},
    {href: RouterPaths.CONTACT, name: 'contact'},
    {href: RouterPaths.PRIVACY, name: 'privacy'},
  ];

  const renderResources = () => (
    <div className="footer__resources flex flex-col desktop:flex-row w-full">
      <ul className="footer__resources__list flex flex-col tablet:flex-row flex-wrap mr-auto">
        {routes.map(({name, href}, j) => (
          <li key={`${name}-${j}`} className="flex items-center">
            <CustomLink
              key={`${name}-${j}`}
              to={href}
              active={false}
              label={t(`navigation:${name}`)}
            />
          </li>
        ))}
      </ul>
      <div className="footer__resources__copy-and-social flex flex-col tablet:flex-row items-start tablet:items-center">
        <p className="footer__resources__copyright text-lg emphasis">
          ©{date.getFullYear()} Else Labs
        </p>
        <ul className="footer__resources__social list-none grid grid-cols-4">
          <li>
            <a href={content.elseInstagram} target="_blank" rel="noreferrer">
              <Instagram />
            </a>
          </li>
          <li>
            <a href={content.elseFacebook} target="_blank" rel="noreferrer">
              <Facebook />
            </a>
          </li>
          <li>
            <a href={content.elseLinkedin} target="_blank" rel="noreferrer">
              <Linkedin />
            </a>
          </li>
          <li>
            <a href={content.elseTwitter} target="_blank" rel="noreferrer">
              <Twitter />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div className="footer flex flex-col items-start tablet:items-center">
      <div className="footer__content flex flex-col tablet:flex-row items-start tablet:items-center">
        <FooterLogo className="footer__content__brand" />
        {renderResources()}
      </div>
    </div>
  );
}

export default Footer;
