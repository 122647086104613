import {useStaticQuery, graphql} from 'gatsby';

const useCommercialPage = () => {
  const {
    contentfulCommercialPage,
    allContentfulCommercialFeaturesAndBenefits,
    allContentfulRecipe,
    allContentfulVersatileForKitchenCards,
  } = useStaticQuery(
    graphql`
      query {
        allContentfulVersatileForKitchenCards {
          nodes {
            media {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              description
            }
            body {
              raw
            }
            title
            order
          }
        }
        allContentfulRecipe {
          nodes {
            title
            order
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 452, placeholder: BLURRED)
                }
              }
            }
          }
        }
        allContentfulCommercialFeaturesAndBenefits {
          nodes {
            icon {
              file {
                url
                contentType
                fileName
              }
            }
            title
            body {
              raw
            }
            order
          }
        }
        contentfulCommercialPage {
          title
          heading
          headingBody {
            raw
          }
          headingImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          videoTitle
          videoThumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          videoUrl
          sectionOneBody {
            raw
          }
          benefits
          benefitIcons {
            file {
              fileName
              url
              contentType
            }
          }
          sectionTwoHeading {
            raw
          }
          ctaHeading {
            raw
          }
          ctaBody {
            raw
          }
          sectionThreeHeading {
            raw
          }
          sectionFourHeading {
            raw
          }
          featureFootnote {
            raw
          }
        }
      }
    `,
  );
  return {
    commercial: contentfulCommercialPage,
    featuresAndBenefits: allContentfulCommercialFeaturesAndBenefits,
    recipes: allContentfulRecipe,
    versatileKitchen: allContentfulVersatileForKitchenCards,
  };
};

export default useCommercialPage;
