import React from 'react';
import {Helmet} from 'react-helmet';
import {useSEO} from '../../hooks/queries';

const SEO = () => {
  const content = useSEO();
  return (
    <Helmet>
      <meta name="robots" content="max-image-preview:large" />
      <meta
        name="robots"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <link rel="canonical" href={content.url} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={content.title} />
      <meta property="og:description" content={content.description} />
      <meta property="og:url" content={content.url} />
      <meta property="og:site_name" content={content.siteName} />
      <meta property="article:publisher" content={content.facebookUrl} />
      <meta
        property="og:image"
        content={`${content.url}${content.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src}`}
      />
      <meta
        property="og:image:width"
        content={content.image.localFile.childImageSharp.gatsbyImageData.width}
      />
      <meta
        property="og:image:height"
        content={content.image.localFile.childImageSharp.gatsbyImageData.height}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={content.title} />
      <meta name="twitter:description" content={content.description} />
      <meta
        name="twitter:image"
        content={`${content.url}${content.twitterImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src}`}
      />
      <meta name="twitter:creator" content={content.twitterUsername} />
      <meta name="twitter:site" content={content.twitterUsername} />
    </Helmet>
  );
};

export default SEO;
