import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'gatsby';
import Logo from '../../../assets/icons/logo.svg';
import MenuIcon from '../../../assets/icons/menu.svg';
import CloseIcon from '../../../assets/icons/close.svg';
import RouterPaths from '../../../values/RouterPaths';
import CustomLink from '../../common/CustomLink/CustomLink';
import './Navigation.scss';

const routes = [
  {href: RouterPaths.COMMERCIAL, name: 'commercial'},
  {href: RouterPaths.HOUSEHOLD, name: 'household'},
  {href: RouterPaths.ABOUT, name: 'about'},
  {href: RouterPaths.CONTACT, name: 'contact'},
];

function Navigation() {
  const {t} = useTranslation();
  const [nav, showNav] = useState(false);
  const [activePage, setActivePage] = useState('');

  const renderRoutes = () =>
    routes.map(({href, name}, index) => (
      <li
        className={`nav__menu__options__item ${
          // add --no-rm modifier if last item in list (no right margin should be applied)
          index === routes.length - 1 ? 'nav__menu__options__item--no-rm' : ''
        }`}
        key={name}>
        <CustomLink
          to={href}
          label={t(`navigation:${name}`)}
          className="custom-link--light"
          active={activePage.includes(name)}
        />
      </li>
    ));

  const renderMobileMenu = () => (
    <div className="burger-menu">
      <div className="burger-menu__bar flex items-center fixed top-0 w-full z-50">
        <MenuIcon
          className="burger-menu__bar__menu-icon cursor-pointer"
          onClick={() => showNav(true)}
        />
        <Link className="burger-menu__bar__brand" to={RouterPaths.COMMERCIAL}>
          <Logo />
        </Link>
      </div>

      <nav className={`nav nav--mobile z-50 ${nav ? 'open-menu' : ''}`}>
        <div className="nav__header flex items-center">
          <Link to={RouterPaths.COMMERCIAL} className="self-start">
            <Logo className="nav__logo" />
          </Link>
          <CloseIcon
            className="focus:outline-none w-6 h-6 cursor-pointer ml-auto"
            onClick={() => showNav(false)}
          />
        </div>
        <ul className="nav__menu__options">{renderRoutes(false)}</ul>
      </nav>
    </div>
  );

  useEffect(() => {
    let currentPage = window.location.pathname.replace('/', '');
    // the landing page is commercial, but that's not in the route name
    // if the route is empty, we can assume it's the commercial page
    if (currentPage.length === 0) {
      currentPage = 'commercial';
    }
    setActivePage(currentPage);
    // Event listener to close menu when it loses focus
    window.addEventListener('click', e => {
      // Only close nav if it's open
      if (nav) {
        if (e.target.getAttribute('class') === null) {
          showNav();
        } else if (
          !e.target.getAttribute('class').includes('nav') &&
          !e.target.getAttribute('class').includes('burger-menu')
        ) {
          showNav();
        }
      }
    });
  }, [nav]);

  return (
    <>
      <nav className="nav nav--desktop flex flex-row justify-center items-center z-50 w-full bg-white">
        <div className="nav__menu flex items-center">
          <Link
            className="nav__menu__brand flex items-center"
            to={RouterPaths.COMMERCIAL}>
            <Logo />
          </Link>
          <ul className="nav__menu__options ml-auto pl-0 font-normal list-none flex">
            {renderRoutes(true)}
          </ul>
        </div>
      </nav>
      {renderMobileMenu()}
    </>
  );
}

export default Navigation;
