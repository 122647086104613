import {useStaticQuery, graphql} from 'gatsby';

const useWaitlistForm = () => {
  const {contentfulWaitlistForm} = useStaticQuery(
    graphql`
      query {
        contentfulWaitlistForm {
          title
          body
          bgImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `,
  );
  return contentfulWaitlistForm;
};

export default useWaitlistForm;
