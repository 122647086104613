import {useStaticQuery, graphql} from 'gatsby';

const useSEO = () => {
  const {contentfulSeo} = useStaticQuery(
    graphql`
      query {
        contentfulSeo {
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          twitterUsername
          url
          facebookUrl
          siteName
          twitterImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `,
  );
  return contentfulSeo;
};

export default useSEO;
