import {useStaticQuery, graphql} from 'gatsby';

const useOliverFleetDemoPage = () => {
  const {contentfulOliverFleetDemoPage} = useStaticQuery(
    graphql`
      query {
        contentfulOliverFleetDemoPage {
          title
          videoUrl
        }
      }
    `,
  );
  return contentfulOliverFleetDemoPage;
};

export default useOliverFleetDemoPage;
