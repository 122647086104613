import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {RadioInput} from '..';

const RadioSelect = ({
  name,
  options,
  selectClasses,
  inputClasses,
  selected,
}) => {
  const [selectedValue, setSelectedValue] = useState(selected);
  return (
    <div className={`radio-select ${selectClasses}`}>
      {options.map((option, index) => (
        <RadioInput
          key={option}
          id={option}
          index={index}
          name={name}
          value={option}
          inputClasses={inputClasses}
          setSelected={setSelectedValue}
          checked={selectedValue === option}
        />
      ))}
    </div>
  );
};

RadioSelect.defaultProps = {
  selected: '',
  selectClasses: '',
  inputClasses: '',
};
RadioSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  selected: PropTypes.string,
};

export default RadioSelect;
