import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import './Select.scss';

const Select = ({
  defaultValue,
  ariaLabel,
  value,
  containerClasses,
  selectClasses,
  selectItemContainerClasses,
  selectItemClasses,
  setValue,
  options,
}) => {
  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(value || defaultValue);
  const handleSelectToggle = () => {
    setOpen(!open);
  };
  const handleSelect = e => {
    setValue(e.target.value);
    setSelectValue(e.target.value);
    handleSelectToggle();
  };

  useEffect(() => {
    // Event listener to close menu when it loses focus
    window.addEventListener('click', e => {
      if (e.target.getAttribute('class') === null) {
        setOpen(false);
      } else if (!e.target.getAttribute('class').includes('select')) {
        setOpen(false);
      }
    });
  }, []);

  return (
    <div className={`select grid relative ${containerClasses}`}>
      <button
        type="button"
        value={value}
        aria-label={ariaLabel}
        className={`select-button text-left focus:outline-none ${selectClasses}`}
        onClick={handleSelectToggle}>
        <span className="select-button-text flex items-center">
          {open ? (
            <FiChevronUp className="select-chevron" />
          ) : (
            <FiChevronDown className="select-chevron" />
          )}
          {selectValue}
        </span>
      </button>
      <div
        className={`${
          !open ? 'hidden' : ''
        } grid absolute z-10 top-10 w-full select-item-container shadow ${selectItemContainerClasses}`}>
        {options.map(option => (
          <button
            type="button"
            key={option}
            value={option}
            className={`select-item text-left focus:outline-none ${selectItemClasses}`}
            onClick={handleSelect}>
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

Select.defaultProps = {
  containerClasses: '',
  selectClasses: '',
  selectItemContainerClasses: '',
  selectItemClasses: '',
};

Select.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  containerClasses: PropTypes.string,
  selectClasses: PropTypes.string,
  selectItemContainerClasses: PropTypes.string,
  selectItemClasses: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default Select;
