import * as React from 'react';
import PropTypes from 'prop-types';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import './TestimonialCard.scss';

function TestimonialCard({image, text, url}) {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className="testimonial flex flex-col justify-center px-12 pb-10 pt-8">
        <div className="mx-auto">
          <GatsbyImage
            image={getImage(image.localFile)}
            alt={image.description}
          />
        </div>
        <div className="text-lg text-center mt-8">{text}</div>
      </div>
    </a>
  );
}

TestimonialCard.defaultProps = {
  image: {},
  text: '',
  url: '',
};

TestimonialCard.propTypes = {
  image: PropTypes.object,
  text: PropTypes.string,
  url: PropTypes.string,
};

export default TestimonialCard;
