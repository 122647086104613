import React from 'react';
import PropTypes from 'prop-types';
import './RadioInput.scss';

const RadioInput = ({
  id,
  name,
  index,
  value,
  checked,
  setSelected,
  inputClasses,
}) => (
  <label
    key={id}
    htmlFor={id}
    className={`radio-label block rounded ${checked ? `checked` : ''}`}>
    <input
      id={id}
      name={name}
      value={value}
      className={`appearance-none ${inputClasses}`}
      type="radio"
      checked={checked}
      onChange={e => {
        setSelected(e.target.value);
      }}
    />
    <span className="radio-letter">
      <b>{String.fromCharCode(97 + index).toUpperCase()}</b>
    </span>
    <span>{value}</span>
  </label>
);

RadioInput.defaultProps = {
  inputClasses: '',
};

RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  inputClasses: PropTypes.string,
};

export default RadioInput;
