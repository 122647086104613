import React from 'react';
import PropTypes from 'prop-types';
import {LightBoxClose} from '../../../assets/icons';
import './VideoLightBox.scss';

function VideoLightBox({isOpen, videoUrl, videoTitle, onClose}) {
  const handleKeyDown = () => onClose();

  return (
    <>
      {isOpen && (
        <div
          tabIndex={0}
          role="button"
          className="lightbox"
          onKeyDown={handleKeyDown}
          onClick={onClose}>
          <div className="lightbox__close">
            <LightBoxClose />
          </div>
          <div className="lightbox__container">
            <iframe
              src={videoUrl}
              title={videoTitle}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </>
  );
}

VideoLightBox.defaultProps = {
  videoUrl: '',
  videoTitle: '',
  isOpen: false,
  onClose: () => {},
};

VideoLightBox.propTypes = {
  videoUrl: PropTypes.string,
  videoTitle: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default VideoLightBox;
