import {useStaticQuery, graphql} from 'gatsby';

const useContactPage = () => {
  const {contentfulContactPage} = useStaticQuery(
    graphql`
      query {
        contentfulContactPage {
          title
          heading
          subheading
          successBody {
            raw
          }
          failureSubheading
          successSubheading
        }
      }
    `,
  );
  return contentfulContactPage;
};

export default useContactPage;
