import {useStaticQuery, graphql} from 'gatsby';

const useNewsletterForm = () => {
  const {contentfulNewsletterForm} = useStaticQuery(
    graphql`
      query {
        contentfulNewsletterForm {
          title
          body
          mobileBgImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          desktopBgImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `,
  );
  return contentfulNewsletterForm;
};

export default useNewsletterForm;
