import {useStaticQuery, graphql} from 'gatsby';

const usePrivacyPolicy = () => {
  const {contentfulPrivacyPage} = useStaticQuery(
    graphql`
      query {
        contentfulPrivacyPage {
          title
          headerTitle
          introduction {
            raw
          }
          body {
            raw
          }
        }
      }
    `,
  );
  return contentfulPrivacyPage;
};

export default usePrivacyPolicy;
