import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import './CustomLink.scss';

const CustomLink = ({label, to, active, theme, className}) => (
  <span className={`custom-link-container custom-link-container--${theme}`}>
    <Link
      className={`flex items-center custom-link custom-link--${theme} ${
        active ? 'font-semibold' : ''
      } text-base ${className}`}
      to={to}>
      {label}
    </Link>
  </span>
);

CustomLink.defaultProps = {
  theme: 'light',
  className: '',
};

CustomLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  theme: PropTypes.string,
  className: PropTypes.string,
};

export default CustomLink;
