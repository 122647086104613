import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import navigationEN from '../locales/en/navigation.json';
import footerEN from '../locales/en/footer.json';
import errorsEN from '../locales/en/errors.json';
import privacyEN from '../locales/en/privacy.json';
import householdEN from '../locales/en/household.json';
import coookiePromptEN from '../locales/en/cookie-prompt.json';
import inputEN from '../locales/en/input.json';
import aboutEN from '../locales/en/about.json';
import commercialEN from '../locales/en/commercial.json';
import contactEN from '../locales/en/contact.json';
import waitlistEN from '../locales/en/waitlist.json';

// the translations
const resources = {
  en: {
    navigation: navigationEN,
    footer: footerEN,
    errors: errorsEN,
    privacy: privacyEN,
    cookiePrompt: coookiePromptEN,
    input: inputEN,
    about: aboutEN,
    household: householdEN,
    commercial: commercialEN,
    contact: contactEN,
    waitlist: waitlistEN,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  resources,
  debug: true,
  keySeparator: '.', // we use content as keys
});

export default i18n;
