import {useStaticQuery, graphql} from 'gatsby';

const useAnnouncementPage = () => {
  const {contentfulAnnouncementPage} = useStaticQuery(
    graphql`
      query {
        contentfulAnnouncementPage {
          title
          announcementTitle {
            raw
          }
          announcementText {
            raw
          }
          aboutElse {
            raw
          }
          mediaContact {
            raw
          }
        }
      }
    `,
  );
  return contentfulAnnouncementPage;
};

export default useAnnouncementPage;
