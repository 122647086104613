import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TextInput from '../TextInput';
import './TextSelect.scss';

const TextSelect = ({
  id,
  label,
  name,
  value,
  underline,
  containerClasses,
  inputContainerClasses,
  inputClasses,
  selectItemContainerClasses,
  selectItemClasses,
  setValue,
  options,
  error,
}) => {
  const maxItems = 5;
  const [open, setOpen] = useState(false);
  const [optionsToShow, setOptionsToShow] = useState(options);
  const handleSelect = e => {
    setValue(e.target.value);
    setOpen(false);
  };
  const handleChange = e => {
    // Only opens drop down if the user has inputted something
    setOpen(e.target.value !== '');
    // Only shows options that fit the user input
    setOptionsToShow(
      options.filter(val =>
        val.toLowerCase().startsWith(e.target.value.toLowerCase()),
      ),
    );
  };

  // TO-DO: Implement a way to disable browser suggestions
  // Clicking out is a way to hide them, but because of this
  // clicking out will also close our suggestions.
  // useEffect(() => {
  //   // Event listener to close menu when it loses focus
  //   window.addEventListener('click', e => {
  //     if (e.target.getAttribute('class') === null) {
  //       setOpen(false);
  //     } else if (
  //       e.target.name !== name &&
  //       !e.target.getAttribute('class').includes('text-select')
  //     ) {
  //       setOpen(false);
  //     }
  //   });
  // }, []);

  return (
    <div className={`text-select grid relative ${containerClasses}`}>
      <TextInput
        id={id}
        name={name}
        label={label}
        value={value}
        underline={underline}
        error={error}
        containerClasses={inputContainerClasses}
        inputClasses={inputClasses}
        handleChange={handleChange}
      />
      <div
        className={`${
          !open ? 'hidden' : ''
        } grid absolute z-10 w-full text-select-item-container shadow ${selectItemContainerClasses}`}>
        {optionsToShow.slice(0, maxItems + 1).map((option, index) =>
          index === maxItems ? (
            <span
              key={option}
              className={`text-select-item ${selectItemClasses}`}>
              ...
            </span>
          ) : (
            <button
              type="button"
              key={option}
              value={option}
              className={`text-select-item text-left focus:outline-none ${selectItemClasses}`}
              onClick={handleSelect}>
              {option}
            </button>
          ),
        )}
      </div>
    </div>
  );
};

TextSelect.defaultProps = {
  underline: '',
  containerClasses: '',
  inputClasses: '',
  inputContainerClasses: '',
  selectItemContainerClasses: '',
  selectItemClasses: '',
  error: '',
};

TextSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  underline: PropTypes.string,
  containerClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  inputContainerClasses: PropTypes.string,
  selectItemContainerClasses: PropTypes.string,
  selectItemClasses: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  error: PropTypes.string,
};

export default TextSelect;
