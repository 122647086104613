import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from 'gatsby';
import RouterPaths from '../../../values/RouterPaths';
import {getCookieValue} from '../../../utils/cookie';

const ProtectedRoute = ({component: Component, ...rest}) => {
  const authorization = getCookieValue('protected_authorization');
  if (authorization !== 'true') {
    navigate(RouterPaths.COMMERCIAL);
  }
  return <Component {...rest} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.element.isRequired,
};

export default ProtectedRoute;
