import React from 'react';
import PropTypes from 'prop-types';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {GatsbyImage} from 'gatsby-plugin-image';
import './MediaTileMobile.scss';

const MediaTile = ({id, image, altText, title, description, classes}) => (
  <div id={id} className={`flex flex-col w-full media-tile--mobile ${classes}`}>
    <div className="flex flex-col py-6 h-full self-center justify-center text-left media-tile--mobile__text">
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      <p className="text-sm tablet:text-lg">
        {documentToReactComponents(JSON.parse(description))}
      </p>
    </div>
    <div className="w-full self-center image">
      <GatsbyImage className="h-full w-full" image={image} alt={altText} />
    </div>
  </div>
);

MediaTile.defaultProps = {
  classes: '',
};

MediaTile.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  classes: PropTypes.string,
  image: PropTypes.object.isRequired,
  altText: PropTypes.string.isRequired,
};

export default MediaTile;
