import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {ChevronLeft, ChevronRight} from '../../../assets/icons';
import Button from '../Button';
import './Carousel.scss';
import ViewPorts from '../../../values/ViewPorts';

const Carousel = ({items}) => {
  const recipeCarousel = useRef();
  const [disableLeft, setDisableLeft] = useState(true);
  const [disableRight, setDisableRight] = useState(false);
  const scrollAmount = {
    desktop: 1000,
    tablet: 750,
    mobile: 250,
  };
  let position = {mouseX: 0, carouselX: 0};

  const updateButtonState = () => {
    const carousel = recipeCarousel.current;
    // handle left button disable when at start of list
    if (carousel.scrollLeft <= 0) {
      setDisableLeft(true);
    } else setDisableLeft(false);
    // handle right button disable when at end of list
    if (
      // remove decimal values
      Math.ceil(carousel.scrollLeft) >=
      carousel.scrollWidth - carousel.offsetWidth
    ) {
      setDisableRight(true);
    } else setDisableRight(false);
  };

  // smooth scroll function
  const getDistance = () => {
    let distance;
    if (window.screen.width < ViewPorts.TABLET) distance = scrollAmount.mobile;
    else if (window.screen.width < ViewPorts.DESKTOP)
      distance = scrollAmount.tablet;
    else distance = scrollAmount.desktop;
    return distance;
  };

  const scroll = (direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      recipeCarousel.current.scrollLeft += step * direction;
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
        updateButtonState();
      }
    }, speed);
  };

  // button handlers
  const scrollLeft = () => {
    if (disableRight) {
      setDisableRight(false);
    }
    const distance = getDistance();
    scroll(-1, 15, distance, 25);
  };

  const scrollRight = () => {
    if (disableLeft) {
      setDisableLeft(false);
    }
    const distance = getDistance();
    scroll(1, 15, distance, 25);
  };

  // drag handler
  const mouseMoveHandler = e => {
    // How far the mouse has been moved
    const dx = e.clientX - position.mouseX;

    // Scroll the element
    recipeCarousel.current.scrollLeft = position.carouselX - dx;
  };

  const mouseUpHandler = () => {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
    document.removeEventListener('touchend', mouseUpHandler);
    recipeCarousel.current.style.cursor = 'grab';
    updateButtonState();
  };

  const mouseDownHandler = e => {
    // Change the cursor and prevent user from selecting the text
    recipeCarousel.current.style.cursor = 'grabbing';
    recipeCarousel.current.style.userSelect = 'none';
    position = {
      // carousel position
      carouselX: recipeCarousel.current.scrollLeft,
      // mouse position
      mouseX: e.clientX,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
    document.addEventListener('touchend', mouseUpHandler);
  };

  return (
    <div className="carousel flex flex-col w-full">
      <div
        tabIndex={0}
        role="listbox"
        ref={recipeCarousel}
        onMouseDown={mouseDownHandler}
        onTouchStart={mouseDownHandler}
        className="carousel__list overflow-x-scroll flex flex-row w-full">
        {items}
      </div>
      <div className="carousel__buttons flex gap-2 justify-center">
        <Button
          icon={<ChevronLeft height={14} width={14} />}
          useIcon
          handleClick={scrollLeft}
          disabled={disableLeft}
        />
        <Button
          icon={<ChevronRight height={14} width={14} />}
          useIcon
          handleClick={scrollRight}
          disabled={disableRight}
        />
      </div>
    </div>
  );
};

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Carousel;
