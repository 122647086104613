import Http from '../utils/http';

const contactSubmit = input =>
  Http.post({
    url: `${process.env.GATSBY_API_URL}/contact/submit`,
    obj: input,
    contentType: 'application/json',
  });

const newsletterSignup = ({firstName, lastName, email, inconspicuousInput}) =>
  Http.post({
    url: `${process.env.GATSBY_API_URL}/newsletter/signup`,
    obj: {firstName, lastName, email, inconspicuousInput},
    contentType: 'application/json',
  });

const requestCountry = input =>
  Http.post({
    url: `${process.env.GATSBY_API_URL}/contact/country/submit`,
    obj: input,
    contentType: 'application/json',
  });

const joinWaitlist = input =>
  Http.post({
    url: `${process.env.GATSBY_API_URL}/waitlist/join`,
    obj: input,
    contentType: 'application/json',
  });

export default {
  contactSubmit,
  requestCountry,
  newsletterSignup,
  joinWaitlist,
};
