import React from 'react';
import PropTypes from 'prop-types';
import {FiArrowRight} from 'react-icons/fi';
import './Button.scss';

const Button = ({
  id,
  label,
  classes,
  labelClasses,
  handleClick,
  disabled,
  icon,
  useIcon,
}) => (
  <button
    className={`button focus:outline-none ${classes} ${
      useIcon === false ? 'button--no-icon' : ''
    }`}
    type="button"
    id={id}
    aria-labelledby={`${id}-label`}
    onClick={handleClick}
    disabled={disabled}>
    <div
      className={`flex items-center justify-center text-base font-semibold ${labelClasses} `}>
      <span id={`${id}-label`}>{label}</span>
      {useIcon ? icon || <FiArrowRight className="arrow-icon" /> : null}
    </div>
  </button>
);

Button.defaultProps = {
  classes: '',
  labelClasses: '',
  disabled: false,
  icon: null,
  useIcon: false,
};

Button.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  labelClasses: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  icon: PropTypes.object,
  useIcon: PropTypes.bool,
};

export default Button;
