import React from 'react';
import PropTypes from 'prop-types';
import {FiArrowRight} from 'react-icons/fi';
import './SubmitButton.scss';

const SubmitButton = ({
  id,
  label,
  classes,
  labelClasses,
  disabled,
  icon,
  useIcon,
}) => (
  <button
    id={id}
    aria-labelledby={`${id}-label`}
    className={`submit-button text-left focus:outline-none ${classes} ${
      useIcon === false ? 'submit-button--no-icon' : ''
    }`}
    type="submit"
    disabled={disabled}>
    <div
      className={`flex items-center justify-center text-base ${labelClasses} `}>
      <span id={`${id}-label`} className="font-semibold">
        {label}
      </span>
      {useIcon ? icon || <FiArrowRight className="arrow-icon" /> : null}
    </div>
  </button>
);

SubmitButton.defaultProps = {
  classes: '',
  labelClasses: '',
  icon: null,
  useIcon: true,
};

SubmitButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.string,
  labelClasses: PropTypes.string,
  icon: PropTypes.object,
  useIcon: PropTypes.bool,
};

export default SubmitButton;
