export const setCookie = (key, val, expDays) => {
  const d = new Date();
  d.setTime(d.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${key}=${val};${expires};path=/;SameSite=Lax`;
};

export const getCookieValue = key => {
  const {cookie} = document;
  const values = cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < values.length; i++) {
    if (values[i].trim().includes(`${key}=`)) {
      return values[i].trim().replace(`${key}=`, '');
    }
  }
  return null;
};
