import React from 'react';
import PropTypes from 'prop-types';
import './TextInput.scss';

const TextInput = ({
  id,
  label,
  name,
  placeholder,
  containerClasses,
  labelClasses,
  inputClasses,
  autofill,
  underline,
  error,
  value,
  handleChange,
}) => (
  <div className={`text-input-container relative ${containerClasses}`}>
    <label
      htmlFor={id}
      className={`text-input-label ${
        error && 'text-input-label--error'
      } font-semibold text-lg ${labelClasses}`}>
      {label}
    </label>
    <input
      id={id}
      name={name}
      type="text"
      autofill={autofill}
      className={`block focus:outline-none text-input text-base w-full ${
        error ? 'text-input--error' : ''
      } ${inputClasses}`}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
    />
    <span
      className={`text-input-underline text-xs font-semibold ${
        error ? 'text-input-underline--error' : ''
      }`}>
      {error || underline}
    </span>
  </div>
);

TextInput.defaultProps = {
  placeholder: '',
  autofill: '',
  underline: ' ',
  error: '',
  containerClasses: '',
  labelClasses: '',
  inputClasses: '',
  handleChange: () => {},
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autofill: PropTypes.string,
  underline: PropTypes.string,
  error: PropTypes.string,
  containerClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  handleChange: PropTypes.func,
};

export default TextInput;
