import {useStaticQuery, graphql} from 'gatsby';

const use404 = () => {
  const {contentfulFourOhFourPage} = useStaticQuery(
    graphql`
      query {
        contentfulFourOhFourPage {
          title
          headerTitle
          headerSubtitle
          headerOverline
          headerBody
          headerImage {
            description
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
      }
    `,
  );
  return contentfulFourOhFourPage;
};

export default use404;
