import {useStaticQuery, graphql} from 'gatsby';

const usePasswordResetPage = () => {
  const {contentfulPasswordResetPage} = useStaticQuery(
    graphql`
      query {
        contentfulPasswordResetPage {
          title
          heading
          body
        }
      }
    `,
  );
  return contentfulPasswordResetPage;
};

export default usePasswordResetPage;
