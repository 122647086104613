import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormik} from 'formik';
import {BgImage} from 'gbimage-bridge';
import {getImage} from 'gatsby-plugin-image';
import * as Sentry from '@sentry/gatsby';
import {useNewsletterForm} from '../../../hooks/queries';
import {
  CheckBox,
  TextInput,
  Button,
  SubmitButton,
  LoadingIndicator,
} from '../..';
import {NewsletterDetailsSchema} from '../../../schemas/NewsletterSchema';
import {sleep} from '../../../utils';
import RouterPaths from '../../../values/RouterPaths';
import Api from '../../../lib/api';
import './NewsletterForm.scss';

const NewsletterForm = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const {title, body, desktopBgImage, mobileBgImage} = useNewsletterForm();
  const localDesktopBg = getImage(desktopBgImage.localFile);
  const localMobileBg = getImage(mobileBgImage.localFile);
  // formState = 0, user input
  // formState = 1, sucess
  // formState = 2, failure
  const [formState, setFormState] = useState(0);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      inconspicuousInput: '',
    },
    validationSchema: NewsletterDetailsSchema,
    onSubmit: async (values, {setErrors, resetForm}) => {
      try {
        setLoading(true);
        await sleep(1000);
        await Api.newsletterSignup(values);
        setFormState(1);
        setLoading(false);
        setAcceptPolicy(false);
        resetForm();
      } catch (e) {
        setLoading(false);
        if (e.status === 400) {
          setErrors({email: 'Email already subscribed'});
        } else {
          Sentry.captureException(e);
          setAcceptPolicy(false);
          setFormState(2);
        }
      }
    },
  });

  const handleReturn = () => {
    setFormState(0);
  };

  const handleCheckbox = () => {
    setAcceptPolicy(!acceptPolicy);
  };

  const handleChange = e => {
    if (formik.values[e.target.name] !== undefined) {
      formik.setFieldValue(e.target.name, e.target.value);
    }
  };
  const localDesktop = [
    'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0) 100%)',
    localDesktopBg,
  ];
  const localMobile = [
    'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0) 100%)',
    localMobileBg,
  ];
  const renderTitle = () => (
    <div
      className={`tablet:w-full desktop:w-full desktop:h-full desktop:w-7/12 ${
        formState !== 0 ? 'hidden desktop:flex' : 'flex'
      }`}>
      <BgImage
        image={localDesktop}
        className="newsletter__form__title w-full flex flex-col items-center justify-center">
        <h2 className="font-semibold desktop:font-bold text-5xl desktop:text-6xl w-full text-center">
          {title}
        </h2>
        <p className="newsletter__tagline desktop:text-lg text-base w-full text-center font-semibold">
          {body}
        </p>
      </BgImage>
    </div>
  );

  return (
    <BgImage
      image={localMobile}
      className="newsletter flex flex-col justify-center items-center">
      <form
        onChange={handleChange}
        onSubmit={formik.handleSubmit}
        className="newsletter__form flex flex-col desktop:flex-row justify-center items-center overflow-hidden w-full">
        <>
          {renderTitle()}
          {formState === 0 && (
            <div className="newsletter__form__inputs flex flex-col tablet:w-full desktop:w-5/12">
              <div className="flex flex-wrap">
                <input
                  name="inconspicuousInput"
                  className="invisible w-0 h-0"
                  type="text"
                  tabIndex="-1"
                  aria-hidden="true"
                  autoComplete="off"
                />
                <TextInput
                  id="layout-newsletter-firstName"
                  name="firstName"
                  label={t('input:firstName')}
                  value={formik.values.firstName}
                  error={formik.errors.firstName}
                  containerClasses="text-input-container w-full"
                />
                <TextInput
                  id="layout-newsletter-lastName"
                  name="lastName"
                  label={t('input:lastName')}
                  value={formik.values.lastName}
                  error={formik.errors.lastName}
                  containerClasses="text-input-container w-full"
                />
                <TextInput
                  id="layout-newsletter-email"
                  name="email"
                  placeholder="example@elselabs.io"
                  label={t('input:email')}
                  value={formik.values.email}
                  error={formik.errors.email}
                  containerClasses="text-input-container--no-bm w-full"
                />
              </div>
              <div className="newsletter__form__checkbox">
                <CheckBox
                  id="acceptStorage"
                  name="acceptStorage"
                  label={
                    <span className="inline text-xs">
                      {t('privacy:storeUserPermission.privacyMessage')}{' '}
                      <a
                        className="text-xs underline z-10"
                        target="_blank"
                        href={RouterPaths.PRIVACY}
                        rel="noreferrer">
                        {t('privacy:storeUserPermission.privacyUrlText')}
                      </a>
                    </span>
                  }
                  handleChange={handleCheckbox}
                  labelClasses="check-label--no-bm col-span-full tablet:col-span-5 desktop:col-span-8"
                  checked={acceptPolicy}
                />
              </div>
              <div className="newsletter__form__submit flex w-full items-center justify-center">
                <SubmitButton
                  id="subscribe-button"
                  label="Subscribe"
                  disabled={
                    !acceptPolicy ||
                    formik.values.email.length === 0 ||
                    formik.values.lastName.length === 0 ||
                    formik.values.firstName.length === 0
                  }
                  useIcon={!!loading}
                  icon={loading ? <LoadingIndicator classes="ml-2" /> : null}
                />
              </div>
            </div>
          )}
          {formState !== 0 && (
            <div className="newsletter__form__inputs text-center tablet:w-full desktop:w-5/12">
              {formState === 1 && (
                <div className="newsletter__form__inputs--success z-10 w-full">
                  <h2 className="font-semibold block desktop:hidden text-5xl w-full text-center">
                    {t('footer:newsletter.title')}
                  </h2>
                  <p className="text-6xl tablet:text-7xl font-semibold">
                    {t('errors:forms.successTitle')}
                  </p>
                  <p>{t('errors:forms.successBody')}</p>
                  <Button
                    id="success-primary"
                    label={t('errors:forms.successButton')}
                    useIcon={false}
                    handleClick={handleReturn}
                  />
                </div>
              )}
              {formState === 2 && (
                <div className="newsletter__form__inputs--failure z-10 w-full">
                  <h2 className="font-semibold block desktop:hidden text-5xl w-full text-center">
                    {t('footer:newsletter.title')}
                  </h2>
                  <p className="text-7xl font-semibold">
                    {t('errors:forms.errorTitle')}
                  </p>
                  <p>{t('errors:forms.requestFailedBody')}</p>
                  <Button
                    id="failure-primary"
                    label={t('errors:forms.resubmitButton')}
                    classes="button--error"
                    handleClick={handleReturn}
                  />
                </div>
              )}
            </div>
          )}
        </>
      </form>
    </BgImage>
  );
};

export default NewsletterForm;
