import React from 'react';
import PropTypes from 'prop-types';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import './IconCard.scss';

const IconCard = ({icon, title, description, richText, classes}) => (
  <div className={`flex flex-row card border ${classes}`}>
    <img
      src={icon?.file?.url}
      alt={icon?.file?.fileName}
      className="card__icon"
    />
    <div className="flex flex-col text-left">
      {title && <h3 className="text-lg font-semibold">{title}</h3>}
      <span className="text-lg">
        {richText
          ? documentToReactComponents(JSON.parse(description))
          : description}
      </span>
    </div>
  </div>
);

IconCard.defaultProps = {
  classes: '',
  title: '',
  richText: true,
};

IconCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  richText: PropTypes.bool,
  classes: PropTypes.string,
  icon: PropTypes.object.isRequired,
};

export default IconCard;
