import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button} from '../..';
import {useCookiePrompt} from '../../../hooks/queries';
import {displayRichText} from '../../../utils';
import './CookiePrompt.scss';

const CookiePrompt = ({classes, handleAccept}) => {
  const {t} = useTranslation();
  const {title, body} = useCookiePrompt();
  return (
    <div className="cookie-prompt-container w-full justify-center flex sticky bottom-0 z-50">
      <div
        className={`cookie-prompt flex flex-wrap w-full items-center ${classes}`}>
        <div className="cookie-prompt__text w-full tablet:w-2/3 ">
          <h3 className="text-2xl">{title}</h3>
          {displayRichText(body.raw)}
        </div>
        <Button
          id="cookie-primary"
          classes="button--light-yellow ml-auto w-full tablet:w-1/4"
          label={t('cookiePrompt:buttons.accept')}
          handleClick={handleAccept}
          useIcon={false}
        />
      </div>
    </div>
  );
};

CookiePrompt.defaultProps = {
  classes: '',
};

CookiePrompt.propTypes = {
  classes: PropTypes.string,
  handleAccept: PropTypes.func.isRequired,
};

export default CookiePrompt;
