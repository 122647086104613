import React from 'react';
import PropTypes from 'prop-types';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {GatsbyImage} from 'gatsby-plugin-image';
import './MediaTile.scss';

const MediaTile = ({image, altText, title, description, classes, type}) => {
  if (type % 2) {
    // picture on left
    return (
      <div
        className={`flex mobile:flex-col tablet:flex-row w-full tile ${classes}`}>
        <div className="flex flex-col h-full tile__text self-center justify-center text-left px-8 desktop:px-14">
          <p className="mobile:text-xl tablet:text-2xl font-semibold mb-4">
            {title}
          </p>
          <p className="mobile:text-base tablet:text-lg">
            {documentToReactComponents(JSON.parse(description))}
          </p>
        </div>
        <div className="h-full desktop:ml-4 tile__image">
          <GatsbyImage
            className="h-full desktop:rounded-3xl"
            image={image}
            alt={altText}
          />
        </div>
      </div>
    );
  }
  // picture on right
  return (
    <div
      className={`flex mobile:flex-col tablet:flex-row tile w-full ${classes}`}>
      <div className="h-full desktop:mr-4 tile__image">
        <GatsbyImage
          className="h-full desktop:rounded-3xl"
          image={image}
          alt={altText}
        />
      </div>
      <div className="flex flex-col h-full self-center justify-center tile__text text-left px-8 desktop:px-14">
        <p className="mobile:text-xl tablet:text-2xl font-semibold mb-4">
          {title}
        </p>
        <p className="text-lg desktop:text-xl">
          {documentToReactComponents(JSON.parse(description))}
        </p>
      </div>
    </div>
  );
};

MediaTile.defaultProps = {
  classes: '',
  type: 1,
};

MediaTile.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  classes: PropTypes.string,
  image: PropTypes.object.isRequired,
  altText: PropTypes.string.isRequired,
  type: PropTypes.number,
};

export default MediaTile;
