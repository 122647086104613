import {useStaticQuery, graphql} from 'gatsby';

const useAboutPage = () => {
  const {
    contentfulAboutPage,
    allContentfulAboutTestimonials,
    allContentfulAboutPressLogos,
  } = useStaticQuery(
    graphql`
      query {
        contentfulAboutPage {
          title
          heroTitle {
            raw
          }
          heroContent {
            heroContent
          }
          newsTitle
          punchLine {
            punchLine
          }
          pressKitTitle
          pressKitText
          pressKitImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            description
          }
        }
        allContentfulAboutTestimonials {
          nodes {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
              }
              description
            }
            title
            url
            text {
              text
            }
            order
          }
        }
        allContentfulAboutPressLogos {
          nodes {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
              }
            }
            url
            order
          }
        }
      }
    `,
  );
  return {
    contentfulAboutPage,
    allContentfulAboutTestimonials,
    allContentfulAboutPressLogos,
  };
};

export default useAboutPage;
