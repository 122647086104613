import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import SEO from './SEO';
import Navigation from './Navigation';
import Footer from './Footer';
import CookiePrompt from './CookiePrompt';
import NewsletterForm from './NewsletterForm';
import WaitlistForm from './WaitlistForm';
import {setCookie, getCookieValue} from '../../utils/cookie';
import './layout.scss';
import '../../utils/i18n';

const Layout = ({children, formType}) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const handleAccept = () => {
    setCookie('acceptCookies', 'true', 60);
    setShowPrompt(false);
  };
  useEffect(() => {
    // Show to cookie prompt only if the user has accepted the cookie (which sets a cookie to indicate)
    // or they have closed to prompt (which is indicated in session storage)
    setShowPrompt(getCookieValue('acceptCookies') !== 'true');
  }, []);
  return (
    <div className="flex flex-col h-screen justify-between w-full">
      <SEO />
      <header className="w-full">
        <Navigation />
      </header>
      <main className="app-wrapper mb-auto flex-grow w-full">{children}</main>
      {formType === 1 ? <NewsletterForm /> : <WaitlistForm />}
      {showPrompt && <CookiePrompt handleAccept={handleAccept} />}
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  formType: PropTypes.number,
};
Layout.defaultProps = {
  formType: 1,
};

export default Layout;
